export function getTimeAgo(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const diffMs = now - date;
  const diffSeconds = Math.round(diffMs / 1000);
  const diffMinutes = Math.round(diffSeconds / 60);
  const diffHours = Math.round(diffMinutes / 60);
  const diffDays = Math.round(diffHours / 24);
  const diffWeeks = Math.round(diffDays / 7);
  const diffMonths =
    now.getMonth() -
    date.getMonth() +
    12 * (now.getFullYear() - date.getFullYear());
  const diffYears = now.getFullYear() - date.getFullYear();

  if (diffSeconds < 60) {
    return `${diffSeconds} second${diffSeconds === 1 ? "" : "s"} ago`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes === 1 ? "" : "s"} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours === 1 ? "" : "s"} ago`;
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays === 1 ? "" : "s"} ago`;
  } else if (diffWeeks < 4) {
    return `${diffWeeks} week${diffWeeks === 1 ? "" : "s"} ago`;
  } else if (diffMonths < 12) {
    return `${diffMonths} month${diffMonths === 1 ? "" : "s"} ago`;
  } else {
    return `${diffYears} year${diffYears === 1 ? "" : "s"} ago`;
  }
}

export function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// Utility function to check if the value is a date
export const isDate = (value) => {
  if (Object.prototype.toString.call(value) === "[object Date]") {
    return !isNaN(value.getTime()); // Ensure it's a valid date
  }
  if (typeof value === "string") {
    const parsedDate = Date.parse(value);
    return !isNaN(parsedDate);
  }
  return value && value.date && value.time;
};

// Normalize function to convert a date object or ISO string to a Date object
export const normalizeDate = (value) => {
  if (typeof value === "string") {
    // If it's an ISO string, return it as a Date object
    return new Date(value);
  }

  if (value && value.date && value.time) {
    // If it's an object with date and time fields, construct a Date object
    const { date, time } = value;
    const { year, month, day } = date;
    const { hour, minute, second } = time;
    return new Date(year, month - 1, day, hour, minute, second); // months are 0-indexed
  }

  return new Date(value); // In case it's already a Date object
};
