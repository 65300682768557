import React, {
  forwardRef,
  useMemo,
  useRef,
  useEffect,
  useState,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import {
  Table,
  Pagination,
  Select,
  Checkbox,
  Spinner,
  Menu,
} from "components/ui";
import TableRowSkeleton from "./loaders/TableRowSkeleton";
import Loading from "./Loading";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { isDate, normalizeDate } from "utils/dateHelper";

const { Tr, Th, Td, THead, TBody, Sorter } = Table;

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const {
    indeterminate,
    onChange,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    ...rest
  } = props;

  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  const handleChange = (e) => {
    onChange(e);
    onCheckBoxChange?.(e);
    onIndeterminateCheckBoxChange?.(e);
  };

  return (
    <Checkbox
      className="mb-0"
      ref={resolvedRef}
      onChange={(_, e) => handleChange(e)}
      {...rest}
    />
  );
});

const DataTable = (props) => {
  const {
    skeletonAvatarColumns,
    noPagination,
    columns,
    data,
    loading,
    onCheckBoxChange,
    onIndeterminateCheckBoxChange,
    onPaginationChange,
    onSelectChange,
    onSort,
    pageSizes,
    selectable,
    skeletonAvatarProps,
    pagingData,
    autoResetSelectedRows,
    infiniteScroll,
    // handleScroll,
    // storedScrollPosition,
    onLoadMore,
    isContextMenu,
    compact,
  } = props;

  const { pageSize, pageIndex, total } = pagingData;

  const [contextMenuId, setContextMenuId] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    xPos: 0,
    yPos: 0,
  });
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);
  const tableData = useMemo(() => localData, [localData]);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setContextMenuId(null);
        document.body.classList.remove("overflow-hidden");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const pageSizeOption = useMemo(
    () =>
      pageSizes.map((number) => ({ value: number, label: `${number} / page` })),
    [pageSizes]
  );

  const handleCheckBoxChange = (checked, row) => {
    if (!loading) {
      onCheckBoxChange?.(checked, row);
    }
  };

  const onContextMenu = (id) => {
    setContextMenuId(id);
  };

  const ContextMenuDropDown = ({ yPos, xPos }) => {
    return (
      <div className="fixed" ref={menuRef} style={{ top: yPos, left: xPos }}>
        <Menu
          variant="dark"
          className=" border rounded-sm p-0"
          style={{ width: 150, maxWidth: 200 }}
        >
          <Link target="_blank" to={`/app/profile-details?id=${contextMenuId}`}>
            <Menu.MenuItem
              eventKey="settings"
              className="text-xs text-start pl-2 p-0"
            >
              Open in new Tab
            </Menu.MenuItem>
          </Link>
        </Menu>
      </div>
    );
  };

  const handleIndeterminateCheckBoxChange = (checked, rows) => {
    if (!loading) {
      onIndeterminateCheckBoxChange?.(checked, rows);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: tableData,
        manualPagination: true,
        manualSortBy: true,
        autoResetSelectedRows,
        autoResetSortBy: false,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        if (selectable) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: (props) => (
                <div>
                  <IndeterminateCheckbox
                    {...props.getToggleAllRowsSelectedProps()}
                    onIndeterminateCheckBoxChange={(e) =>
                      handleIndeterminateCheckBoxChange(
                        e.target.checked,
                        props.rows
                      )
                    }
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps()}
                    onCheckBoxChange={(e) =>
                      handleCheckBoxChange(e.target.checked, row.original)
                    }
                  />
                </div>
              ),
              sortable: false,
            },
            ...columns,
          ]);
        }
      }
    );

  const handlePaginationChange = (page) => {
    if (!loading) {
      onPaginationChange?.(page);
    }
  };

  const handleSelectChange = (value) => {
    if (!loading) {
      onSelectChange?.(Number(value));
    }
  };

  const handleSort = (column) => {
    if (!loading) {
      const { id, isSortedDesc, toggleSortBy, clearSortBy, accessor } = column;
      const sortOrder = isSortedDesc ? "desc" : "asc";
      toggleSortBy(!isSortedDesc);

      const sortedData = [...localData].sort((a, b) => {
        const valueA = typeof accessor === "function" ? accessor(a) : a[id];
        const valueB = typeof accessor === "function" ? accessor(b) : b[id];

        console.log("valueA,valueB", valueA, valueB);

        // Check if the column value is a date (either string or Date object)
        const isDateColumn = isDate(valueA) && isDate(valueB);

        if (isDateColumn) {
          // If it's a date column, compare as Date objects
          const dateA = normalizeDate(valueA);
          const dateB = normalizeDate(valueB);
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        } else {
          // For non-date columns, compare as usual (lexicographically for strings or numerically for numbers)
          if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
          if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
        }

        return 0;
      });

      setLocalData(sortedData);
      onSort?.({ order: sortOrder, key: id }, { id, clearSortBy });
    }
  };

  return (
    <Loading loading={loading && data.length !== 0} type="cover">
      {infiniteScroll === true ? (
        <InfiniteScroll
          dataLength={data.length} //This is important field to render the next data
          next={onLoadMore}
          hasMore={data.length !== total}
          loader={
            <div className="flex items-center">
              {loading ? <Spinner size="40px" className="mx-auto" /> : null}
            </div>
          }
          endMessage={
            loading ? (
              <div className="flex items-center">
                <Spinner size="40px" className="mx-auto" />
              </div>
            ) : (
              <div className="flex items-center">
                <b className="mx-auto">That's all we have for the moment.</b>
              </div>
            )
          }
        >
          <Table {...getTableProps()} compact={compact}>
            <THead>
              {headerGroups.map((headerGroup) => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Th {...column.getHeaderProps()}>
                      {column.render("Header") &&
                        (column.sortable ? (
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSort(column)}
                          >
                            {column.render("Header")}
                            <span>
                              <Sorter sort={column.isSortedDesc} />
                            </span>
                          </div>
                        ) : (
                          <div>{column.render("Header")}</div>
                        ))}
                    </Th>
                  ))}
                </Tr>
              ))}
            </THead>
            {loading && data.length === 0 ? (
              <TableRowSkeleton
                columns={columns.length}
                rows={pagingData.pageSize}
                avatarInColumns={skeletonAvatarColumns}
                avatarProps={skeletonAvatarProps}
              />
            ) : (
              <TBody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </TBody>
            )}
          </Table>
        </InfiniteScroll>
      ) : (
        <Table {...getTableProps()} compact={compact}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()}>
                    {column.render("Header") &&
                      (column.sortable ? (
                        <div
                          className="cursor-pointer"
                          onClick={() => handleSort(column)}
                        >
                          {column.render("Header")}
                          <span>
                            <Sorter sort={column.isSortedDesc} />
                          </span>
                        </div>
                      ) : (
                        <div>{column.render("Header")}</div>
                      ))}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          {loading && data.length === 0 ? (
            <TableRowSkeleton
              columns={columns.length}
              rows={pagingData.pageSize}
              avatarInColumns={skeletonAvatarColumns}
              avatarProps={skeletonAvatarProps}
            />
          ) : (
            <TBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Fragment key={i}>
                    {contextMenuId === row?.original?.studentId && (
                      <ContextMenuDropDown
                        xPos={contextMenuPosition.xPos}
                        yPos={contextMenuPosition.yPos}
                      />
                    )}
                    <Tr
                      {...row.getRowProps()}
                      onContextMenu={
                        isContextMenu
                          ? (e) => {
                              e.preventDefault();
                              setContextMenuPosition({
                                xPos: e.clientX,
                                yPos: e.clientY,
                              });
                              document.body.classList.add("overflow-hidden");
                              onContextMenu(row.original.studentId);
                            }
                          : null
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <Td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  </Fragment>
                );
              })}
            </TBody>
          )}
        </Table>
      )}
      {noPagination !== true && (
        <div className="flex items-center justify-between mt-4">
          <Pagination
            pageSize={pageSize}
            currentPage={pageIndex}
            total={total}
            onChange={handlePaginationChange}
          />
          <div style={{ minWidth: 130 }}>
            <Select
              size="sm"
              menuPlacement="top"
              isSearchable={false}
              value={pageSizeOption.filter(
                (option) => option.value === pageSize
              )}
              options={pageSizeOption}
              onChange={(option) => handleSelectChange(option.value)}
            />
          </div>
        </div>
      )}
    </Loading>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  onIndeterminateCheckBoxChange: PropTypes.func,
  onPaginationChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSort: PropTypes.func,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  selectable: PropTypes.bool,
  skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
  skeletonAvatarProps: PropTypes.object,
  pagingData: PropTypes.shape({
    total: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  autoResetSelectedRows: PropTypes.bool,
};

DataTable.defaultProps = {
  pageSizes: [10, 25, 50, 100],
  pagingData: {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  },
  data: [],
  columns: [],
  selectable: false,
  loading: false,
  autoResetSelectedRows: true,
};

export default DataTable;
